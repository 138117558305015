<template>
    <v-card dark class="rounded-lg " color="rgba(0,0,0,0.4)" min-height="100%">
        <v-tabs v-model="tab" background-color="transparent">
            <v-tab>Chats</v-tab>
            <v-tab>Enviados</v-tab>
            <!-- <v-tab>Contacto</v-tab>
            <v-tab>Search</v-tab> -->
        </v-tabs>
        <v-row class="title" v-if="!finishedLoading">
            <v-col cols="2">
                <v-progress-circular indeterminate />
            </v-col>
            <v-col cols="auto">
                Cargando contactos...
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-progress-circular indeterminate v-if="(continueMailing && this.currentMailContact > 0) || (continueMailingSearch && this.currentMailContactSearch > 0) || (continueMessaging && this.currentMessageContact > 0)" />
                <div v-if="continueMessaging"> Enviando Mensajes: {{this.currentMessageContact}} de {{ this.countMessageContact }}</div>
                <v-spacer />
                <!-- <div v-if="continueMailing"> Enviando Carta Contactos: {{this.currentMailContact}} de {{ this.countMailContact }}</div>
                <v-spacer />
                <div v-if="continueMailingSearch">Cartas Search Enviadas: {{this.successfullMailsSearch}}</div> -->
            </v-col>
        </v-row>
        <v-tabs-items v-model="tab" class="transparent" v-if="finishedLoading">

            <v-tab-item>
                <v-card dark color="rgba(0,0,0,0)">
                    <v-subheader>
                        <v-row class="title">
                            <v-col cols="auto" >
                                <v-btn @click="stopMessaging" color="error">Stop Chat</v-btn>
                                <!-- <v-btn @click="stopSendingMails" color="error">Stop Contactos</v-btn>
                                <v-btn @click="stopSendingMailsSearch" color="error">Stop Search</v-btn> -->
                            </v-col>
                        </v-row>
                    </v-subheader>
                    <v-card-text class="pt-0 accounts-container custom-scroll">
                        <v-container>
                            <v-radio-group
                                v-model="chatType"
                                row
                            >
                                <v-radio
                                    :label="getTextboxLabel(1)"
                                    :value="1"
                                ></v-radio>
                                <v-radio
                                    :label="getTextboxLabel(2)"
                                    :value="2"
                                ></v-radio>
                            </v-radio-group>
                        </v-container>
                        <v-textarea
                            v-model="chatBlacklist"
                            :label="getTextboxLabel(chatType)"
                            placeholder="Ingrese los IDs separados por comas de los clientes"
                            outlined
                            rows="3"
                            />
                       <!--   -->
                    </v-card-text>
                    <v-card-text class="d-flex">
                        <v-file-input v-show="showInput" v-model="messageAttatchment" />
                        <!-- <v-file-input
                            v-model="messageAttatchment"
                            accept="image/png, image/jpeg, image/bmp video/mp4 audio/mp3"
                            label="Seleccione un archivo"
                        ></v-file-input> -->
                        <v-text-field v-model="newMessage" v-show="!showInput" label="Chats Masivos" outlined placeholder="Ingrese el mensaje que desea enviarle a todos los contactos"></v-text-field>
                        <v-btn icon @click="toggleInput">
                            <v-icon>mdi-paperclip</v-icon>
                        </v-btn>
                        <v-btn @click="startMessaging" color="primary" :loading="loadingChats">Send</v-btn>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card dark color="rgba(0,0,0,0)">
                    <v-card-text class="pt-0 accounts-container custom-scroll">
                        <v-list subheader two-line dense color="rgba(0,0,0,0.4)">
                            <v-container v-for="(contact, index) in sentMessages" :key="index">
                                <v-list-item :class="getChatBubbleColor(contact[1])">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">{{ contact[0] }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-container>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <!-- <v-tab-item>
                <v-card dark color="rgba(0,0,0,0)">
                    <v-subheader>
                        <v-row class="title">
                            <v-col cols="auto" >Enviar Mail a todos los contactos</v-col>
                        </v-row>
                    </v-subheader>
                    <v-card-text class="pt-0">
                        <v-container>
                            <v-radio-group
                                v-model="mailType"
                                row
                            >
                                <v-radio
                                    :label="getTextboxLabel(1)"
                                    :value="1"
                                ></v-radio>
                                <v-radio
                                    :label="getTextboxLabel(2)"
                                    :value="2"
                                ></v-radio>
                            </v-radio-group>
                        </v-container>
                        <v-textarea
                            v-model="mailBlacklist"
                            :label="getTextboxLabel(mailType)"
                            placeholder="Ingrese los IDs separados por comas de los clientes"
                            outlined
                            rows="3"
                            />
                        <v-text-field v-model="mailTitle" label="Titulo" outlined></v-text-field>
                        <v-textarea
                            v-model="mailText"
                            label="Mensaje"
                            placeholder="Mensaje"
                            outlined
                            rows="7"
                            class="pb-0 mb-0"
                            />
                        <v-file-input
                            v-model="mailAttatchment"
                            accept="image/png, image/jpeg, image/bmp video/mp4"
                            label="Seleccione un archivo"
                        ></v-file-input>
                    </v-card-text>
                    <v-card-text class="d-flex">
                        <v-btn @click="startMailing" color="primary" :loading="loadingMails">Send</v-btn>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card dark color="rgba(0,0,0,0)">
                    <v-subheader>
                        <v-row class="title">
                            <v-col cols="auto" >Enviar Mail a Search</v-col>
                        </v-row>
                    </v-subheader>
                    <v-card-text class="pt-0">
                        <v-autocomplete
                            v-model="selectedCountry"
                            :items="countries"
                            item-value="code"
                            item-text="name"
                            label="Country"
                            return-object
                            hide-details
                            outlined
                            filled
                            dense
                        />
                        <v-select
                            :items="sexOptions"
                            v-model="sex"
                            label="Sex"
                            item-text="name"
                            hide-details
                            :rules="[ rules.select ]"
                            return-object
                            outlined
                            filled
                            dense
                        >
                            <template v-slot:item="{item}">
                                <v-row dense class="grey lighten-5 subtitle-1">
                                <v-col>
                                    {{item.name}}
                                </v-col>
                                <v-col cols="auto">
                                    <v-icon :color="item.color">{{item.icon}}</v-icon>
                                </v-col>
                                <v-col cols="auto">
                                    <v-icon :color="item.color_alt">{{item.icon_alt}}</v-icon>
                                </v-col>
                                </v-row>
                            </template>
                        </v-select>
                        <v-row>
                             <v-col class="mt-1 ml-1 title">
                                Edad:
                                </v-col>

                                <v-col cols="4">
                                    <v-select 
                                    v-model="selectedAgeFrom"
                                    :items="agesFrom"
                                    :rules="[ rules.select ]"
                                    hide-details
                                    outlined
                                    filled
                                    dense
                                    >
                                    </v-select>
                                </v-col>

                                <v-col cols="auto" class="mt-2">
                                -
                                </v-col>

                                <v-col cols="4">
                                    <v-select 
                                    v-model="selectedAgeTo"
                                    :items="agesTo"
                                    :rules="[ rules.select ]"
                                    hide-details
                                    outlined
                                    filled
                                    dense
                                    >
                                    </v-select>
                                </v-col>

                        </v-row>
                        <v-select
                                v-if="pages !== []"
                                :items="pages"
                                v-model="selectedPage"
                                label="Página"
                                item-text="name"
                                hide-details
                                :rules="[ rules.select ]"
                                return-object
                                outlined
                                filled
                                dense
                            >
                            <template v-slot:item="{item}">
                                <v-row dense class="grey lighten-5 subtitle-1">
                                    <v-col>
                                        {{item.name}}
                                    </v-col>
                                </v-row>
                            </template>
                        
                        </v-select>
                        <v-text-field v-model="mailTitleSearch" label="Titulo" outlined></v-text-field>
                        <v-file-input
                            v-model="mailAttatchmentSearch"
                            accept="image/png, image/jpeg, image/bmp video/mp4"
                            label="Seleccione un archivo"
                        ></v-file-input>
                        <v-textarea
                            v-model="mailTextSearch"
                            label="Mensaje"
                            placeholder="Mensaje"
                            outlined
                            rows="7"
                            class="pb-0 mb-0"
                            />
                    </v-card-text>
                    <v-card-text class="d-flex">
                        <v-btn @click="getPotentialClients" color="primary" :loading="loadingMailsSearch">Send</v-btn>
                    </v-card-text>
                </v-card>
            </v-tab-item> -->
        </v-tabs-items>
    </v-card>
</template>

<script>
import AppData from '@mixins/AppData' 

export default {
    mixins:[ AppData],
    data:() => ({
        showInput: false,
        messages: [],
        contacts: [],
        clients: [],
        newMessage: '',
        chatBlacklist: '',
        sentMessages: [],
        client: '',
        profile: '',
        tab: 0,
        mailText: '',
        mailTitle: '',
        mailBlacklist: '',
        mailTextSearch: '',
        mailTitleSearch: '',
        continueMessaging: false,
        continueMailing: false,
        continueMailingSearch: false,
        loadingChats: false,
        loadingMails: false,
        loadingMailsSearch: false,
        currentMessageContact: 0,
        currentMailContact: 0,
        currentMailContactSearch: 0,
        countMessageContact: 0,
        countMailContact: 0,
        messageAttatchment: null,
        mailAttatchment: null,
        mailAttatchmentSearch: null,
        delay: 1000,
        chatType: 0,
        mailType: 0,
        //loading: true,
        finishedLoading: false,
        successfullMails: 0,
        successfullMailsSearch: 0,
        selectedCountry: null,
        selectedAgeFrom: null,
        selectedAgeTo:   null,
        selectedPage:    null,
        agesFrom:   [18,20,25,30,35,40,45,50,55,60,65,70,75],
        agesTo:     [20,25,30,35,40,45,50,55,60,65,70,75,80],
        countries:  [],
        pages:  [],
        sex: null,
        sexFrom: null,
        sexTo:   null,
        sexOptions: [
            { name: 'Hombre -> Mujer',  from: 'mal', to: 'fem', icon: 'mdi-human-male', icon_alt: 'mdi-human-female', color: 'blue', color_alt: 'pink' },
            { name: 'Mujer  -> Hombre', from: 'fem', to: 'mal', icon: 'mdi-human-female', icon_alt: 'mdi-human-male', color: 'pink', color_alt: 'blue' },
            { name: 'Hombre -> Hombre', from: 'mal', to: 'mal', icon: 'mdi-human-male', icon_alt: 'mdi-human-male', color: 'blue', color_alt: 'blue' },
            { name: 'Mujer  -> Mujer',  from: 'fem', to: 'fem', icon: 'mdi-human-female', icon_alt: 'mdi-human-female', color: 'pink', color_alt: 'pink' },
        ]
    }),
    created() {
        this.getPages()
        this.getCountries()
        this.getContacts()
    },
    methods: {
        getContacts() {
            this.getResource(`profile/${this.$route.params.profile_id}/contacts`).then( data => {
                this.profile = this.$route.params.profile_id
                this.contacts = data;
                this.finishedLoading = true;
            })
        },

        // Chats
        async startMessaging(){
            this.continueMessaging = true
            this.loadingChats = true
            this.tab = 0
            this.sendMessageToAll()
        },
        sendMessageToAll() {
            let contacts = (this.chatType == 2) ? this.parseContactString(this.chatBlacklist) : this.contacts
            this.countMessageContact = contacts.length
            console.log(this.chatType,this.parseContactString(this.chatBlacklist), contacts)
            let contact = contacts[this.currentMessageContact]
            if (!this.continueMessaging || this.currentMessageContact >= contacts.length) {
                this.newMessage = ''
                this.loadingChats = false
                this.currentMessageContact = 0
                return
            }
            if(this.messageAttatchment === null) {
                this.sendMessage(contact)
            } else {
                this.sendMessageWithAttachment(contact)
            }
            this.currentMessageContact++
            setTimeout(this.sendMessageToAll, this.delay)
        },
        sendMessage(contact) {
            console.log(contact)
            if (this.chatType == 1 && this.chatBlacklist.replace(/\s/g,'').split(',').includes(contact)) {
                this.sentMessages.push([`Ignorando contacto ID: ${contact}`, 0])
                return
            }
            
            this.postResource(`client/${contact}/profile/${this.profile}`,  {message: this.newMessage})
            .then( data => {
                this.sentMessages.push([`Mensaje enviado a contacto ID: ${contact}`, 4])
            })
            .catch( data => {
                this.sentMessages.push([`El mensaje no se pudo enviar al contacto ID: ${contact}`, 1])
            })
        },
        sendMessageWithAttachment(contact) {
            if (this.chatType == 1 && this.chatBlacklist.replace(/\s/g,'').split(',').includes(contact)) {
                this.sentMessages.push([`Ignorando contacto ID: ${contact}`, 0])
                return
            }
            /* let contact = this.contacts[this.currentMessageContact]
            if (this.chatBlacklist.replace(/\s/g,'').split(',').includes(contact)) {
                this.sentMessages.push([`Ignorando contacto ID: ${contact}`, 0])
                return
            } */
            let formData = new FormData()
            formData.append('file', this.messageAttatchment)
            formData.append('message', "")
            this.postResourceWithFile(`client/${contact}/profile/${this.profile}`, formData)
            //this.postResource(`client/${contact}/profile/${this.profile}`,  {message: this.newMessage})
            .then( data => {
                this.sentMessages.push([`Mensaje enviado a contacto ID: ${contact}`, 4])
            })
            .catch( data => {
                this.sentMessages.push([`El mensaje no se pudo enviar al contacto ID: ${contact}`, 1])
            })
        },
        stopMessaging(){
            this.messageAttatchment = null
            this.continueMessaging = false
        },
        //Mails
        async startMailing(){
            this.continueMailing = true
            this.loadingMails = true
            this.tab = 0
            await this.sendMailToAll()
        },
        async sendMailToAll() {
            let contacts = (this.mailType == 2) ? this.parseContactString(this.mailBlacklist) : this.contacts
            this.countMailContact = contacts.length
            if (!this.continueMailing || this.currentMailContact >= contacts.length) {
                this.mailText = ''
                this.mailTitle = ''
                this.loadingMails = false
                this.currentMailContact = 0
                return
            }
            let contact = contacts[this.currentMailContact]
            if (this.mailType == 1 && this.mailBlacklist.replace(/\s/g,'').split(',').includes(contact)) {
                this.sentMessages.push([`Ignorando contacto ID: ${contact}`, 0])
                this.currentMailContact++
                return setTimeout(this.sendMailToAll, 500)
            }
            if(this.mailAttatchment === null) {
                this.sendMail(contact)
            } else {
                this.sendMailWithAttachment(contact)
            }
            this.currentMailContact++
            setTimeout(this.sendMailToAll, this.delay)
        },
        sendMail(contact) {
            //let contact = this.contacts[this.currentMailContact]
            this.postResource(`client/${contact}/mail/${this.profile}/${true}`,  
            {
                text: this.mailText,
                subject: this.mailTitle,
            })
            .then( data => {
                if(data.hasOwnProperty('success') && data.success == true){
                    this.sentMessages.push([`Mail enviado a contacto ID: ${contact}`, 2])
                    this.successfullMails++
                } else {
                    this.sentMessages.push([`Mail no se pudo enviar al contacto ID: ${contact}`, 1])
                }
            })
            .catch( data => {
                this.sentMessages.push([`Mail no se pudo enviar al contacto ID: ${contact}`, 1])
            })
        },
        sendMailWithAttachment(contact) {
            //let contact = this.contacts[this.currentMailContact]
            let formData = new FormData()
            formData.append('file', this.mailAttatchment)
            formData.append('text', this.mailText)
            formData.append('subject', this.mailTitle)
            this.postResourceWithFile(`client/${contact}/mail/${this.profile}/${true}`, formData)
            .then( data => {
                if(data.hasOwnProperty('success') && data.success == true){
                    this.sentMessages.push([`Mail enviado a contacto ID: ${contact}`, 2])
                    this.successfullMails++
                } else {
                    this.sentMessages.push([`Mail no se pudo enviar al contacto ID: ${contact}`, 1])
                }
            })
            .catch( data => {
                this.sentMessages.push([`Mail no se pudo enviar al contacto ID: ${contact}`, 1])
            })
        },
        stopSendingMails(){
            this.mailAttatchment = null
            this.continueMailing = false
            this.mailText = ''
            this.mailTitle = ''
        },
        getChatBubbleColor(index){
            switch (index) {
                case 0:
                    return "ignored"
                case 1:
                    return "error"
                case 2:
                    return "profile"
                case 3:
                    return "search"
                case 4:
                    return "chat"
                default:
                    return "profile"
            }
        },
        getCountries(){
            this.getResource('country').then(data =>{
                this.countries = data
            })
        },
        getPages(){
            this.getResource(`providers/${this.$route.params.profile_id}`).then(data =>{
                this.pages = data
            })
        },





      //Search

        getPotentialClients() {
            let ageFrom  = (this.selectedAgeFrom > this.selectedAgeTo ) ? this.selectedAgeTo   : this.selectedAgeFrom
            let ageTo    = (this.selectedAgeTo < this.selectedAgeFrom ) ? this.selectedAgeFrom : this.selectedAgeTo
            this.postResource(`profile/${this.$route.params.profile_id}/clients`, {
                contacts: this.contacts,
                filters: { country: this.selectedCountry.code, ageFrom: ageFrom, ageTo: ageTo, sexFrom: this.sex.from, sexTo: this.sex.to }
            }).then( data => {
                this.clients = data;
                this.loadingMailsSearch = true
            }).finally(data => {
                this.startMailingSearch()
            })
        },
        async startMailingSearch(){
            this.continueMailingSearch = true
            this.loadingMailsSearch = true
            this.tab = 0
            await this.sendMailToAllSearch()
        },
        async sendMailToAllSearch() {
            let contacts = this.clients
            if (!this.continueMailingSearch || this.currentMailContactSearch >= contacts.length) {
                this.mailTextSearch = ''
                this.mailTitleSearch = ''
                this.loadingMailsSearch = false
                this.currentMailContactSearch = 0
                return
            }
            if(this.mailAttatchmentSearch === null) {
                this.sendMailSearch()
            } else {
                this.sendMailSearchWithAttachment()
            }
            this.currentMailContactSearch++
            setTimeout(this.sendMailToAllSearch, this.delay)
        },
        sendMailSearch() {
            let contact = this.clients[this.currentMailContactSearch].amolatina_id
            this.postResource(`client/${contact}/mail/${this.profile}/${false}`,  
            {
                text: this.mailTextSearch,
                subject: this.mailTitleSearch,
                provider: this.selectedPage
            })
            .then( data => {
                if(data.hasOwnProperty('success') && data.success == true){
                    this.sentMessages.push([`Carta enviada a search ID: ${contact}`, 3])
                    this.successfullMailsSearch++
                } else {
                    this.sentMessages.push([`Carta no se pudo enviar al search ID: ${contact}`, 1])
                }
            })
            .catch( data => {
                this.sentMessages.push([`Carta no se pudo enviar al search ID: ${contact}`, 1])
            })
        },
        sendMailSearchWithAttachment() {
            let formData = new FormData()
            formData.append('file', this.mailAttatchmentSearch)
            formData.append('text', this.mailTextSearch)
            formData.append('subject', this.mailTitleSearch)
            formData.append('provider', this.selectedPage)
            let contact = this.clients[this.currentMailContactSearch].amolatina_id
            this.postResourceWithFile(`client/${contact}/mail/${this.profile}/${false}`, formData)
            .then( data => {
                if(data.hasOwnProperty('success') && data.success == true){
                    this.sentMessages.push([`Carta enviada a search ID: ${contact}`, 3])
                    this.successfullMailsSearch++
                } else {
                    this.sentMessages.push([`Carta no se pudo enviar al search ID: ${contact}`, 1])
                }
            })
            .catch( data => {
                this.sentMessages.push([`Carta no se pudo enviar al search ID: ${contact}`, 1])
            })
        },
        stopSendingMailsSearch(){
            this.mailAttatchmentSearch = null
            this.continueMailingSearch = false
            this.mailTextSearch = ''
            this.mailTitleSearch = ''
        },



        toggleInput() {
            this.showInput = !this.showInput;
        },
        getTextboxLabel(type) {
            switch (type) {
                case 1:
                    return 'Blacklist'
                    break;
                case 2:
                    return 'Collection'
                    break;
                default:
                    return 'Blacklist'
                    break;
            }

        },

        parseContactString(string) {
            return string.replace(/\s/g,'').split(',')
        } 
    },
};
</script>

<style scoped>
    .client {
        background: rgb(75, 75, 92);
        border-radius: 20px;
        color: white;
        width: fit-content;
        max-width: 80%;
    }
    .profile {
        background: rgb(32, 150, 32);
        border-radius: 20px;
        color: white;
        width: fit-content;
        max-width: 80%;
        margin-left: auto;
    }

    .search {
        background: rgb(34, 64, 170);
        border-radius: 20px;
        color: white;
        width: fit-content;
        max-width: 80%;
        margin-left: auto;
    }

    .chat {
        background: rgb(158, 165, 15);
        border-radius: 20px;
        color: white;
        width: fit-content;
        max-width: 80%;
        margin-left: auto;
    }

    .error {
        background: rgb(250, 32, 25);
        border-radius: 20px;
        color: white;
        width: fit-content;
        max-width: 80%;
    }

    .ignored {
        background: black;
        border-radius: 20px;
        color: white;
        width: fit-content;
        max-width: 80%;
    }

    .accounts-container{
        height: 60vh;
        overflow-y: auto;
    }

    .is-letter{
        content: "read-more";
    }
    
    .transparent {
        background-color: rgba(0,0,0,0);
    }
</style>