var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)" },
    },
    [
      _c(
        "v-subheader",
        [
          _c(
            "v-row",
            { staticClass: "title" },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-account-multiple-outline"),
                  ]),
                  _vm._v("  Perfiles\n          "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "", loading: _vm.loading } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.list()
                            },
                          },
                        },
                        [_vm._v("mdi-reload")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        color:
                          _vm.myProfilesStarted.length > 0
                            ? "error"
                            : "success",
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setPresence()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.myProfilesStarted.length > 0
                                ? "mdi-stop"
                                : "mdi-play"
                            )
                        ),
                      ]),
                      _vm._v(
                        "\n                 " +
                          _vm._s(
                            _vm.myProfilesStarted.length > 0
                              ? "pausar"
                              : "Iniciar"
                          ) +
                          " \n              "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pt-0 accounts-container custom-scroll" },
        [
          _c(
            "v-list",
            {
              staticClass: "rounded-lg",
              attrs: {
                subheader: "",
                "two-line": "",
                dense: "",
                color: "rgba(0,0,0,0.4)",
              },
            },
            _vm._l(_vm.profiles, function (profile) {
              return _c(
                "v-list-item",
                {
                  key: profile.id,
                  class:
                    _vm.unreadMessages[String(profile.id)] > 0
                      ? "unreadAlert"
                      : "",
                  attrs: {
                    value: profile,
                    color: _vm.myProfilesStarted.includes(profile.id)
                      ? "green"
                      : "blue",
                  },
                },
                [
                  _c(
                    "v-list-item-avatar",
                    { attrs: { color: "blue", size: "60" } },
                    [
                      _c("v-img", {
                        attrs: {
                          src: `/storage/photo/profile/${
                            profile.photo || "nophoto"
                          }.jpg`,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "blue" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatNumber(
                                                          profile.presence_day_sum_bonus
                                                        ) || 0
                                                      ) +
                                                        "  /  " +
                                                        _vm._s(
                                                          profile
                                                            .user_profile_assigned
                                                            .goal_day
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Ganacias Dia")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "red" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        parseInt(
                                                          profile.presence_day_sum_writeoff ||
                                                            0
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Perdidas Dia")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-progress-linear", {
                                    staticClass: "mb-2 mt-1",
                                    attrs: {
                                      value:
                                        (profile.presence_day_sum_bonus * 100) /
                                        profile.user_profile_assigned.goal_day
                                          ? profile.user_profile_assigned
                                              .goal_day
                                          : 1 || 0,
                                      color: "blue",
                                      height: "8",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("v-progress-linear", {
                                    staticClass: "mb-2 mt-1",
                                    attrs: {
                                      value:
                                        profile.presence_day_count_writeoff ||
                                        0,
                                      color: "red",
                                      height: "8",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "pt-2" },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", [_vm._v(_vm._s(profile.name))]),
                              _vm._v(" "),
                              _vm.myProfilesStarted.includes(profile.id) &&
                              profile.user &&
                              profile.password &&
                              profile.agency.status_id == 1
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "profile",
                                              params: {
                                                profile_id: profile.id,
                                              },
                                            },
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _c("list-simple-icon", {
                                            attrs: {
                                              icon: "mdi-account-arrow-left",
                                              label: "Logear Perfil",
                                              color: "blue darken-3",
                                              size: 22,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.myProfilesStarted.includes(profile.id)
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "auto" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showDetails(profile)
                                        },
                                      },
                                    },
                                    [
                                      _c("list-simple-icon", {
                                        attrs: {
                                          icon: "mdi-magnify",
                                          label: "Detalles Puntos",
                                          color: "blue darken-3",
                                          size: 22,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: profile.presence
                                          ? "green"
                                          : "red",
                                        size: "20",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " \n                    " +
                                          _vm._s(
                                            profile.presence
                                              ? "mdi-checkbox-blank-circle"
                                              : "mdi-checkbox-blank-circle-outline"
                                          ) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-icon",
                    [
                      profile.presence &&
                      _vm.myProfilesStarted.includes(profile.id)
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "green" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "x-small": "",
                                                  fab: "",
                                                  color: "green",
                                                  loading: _vm.loading,
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_c("v-icon", [_vm._v("mdi-check")])],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_vm._v(" "), _c("span", [_vm._v("Iniciado")])]
                          )
                        : profile.presence &&
                          !_vm.myProfilesStarted.includes(profile.id)
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "red" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "no-drop",
                                            attrs: { icon: "" },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color: "red",
                                                      size: "32",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("mdi-lock")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "span",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "16", left: "" } },
                                    [_vm._v("mdi-account")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        profile.presence?.user?.full_name
                                      ) +
                                      "  "
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "blue" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "x-small": "",
                                                  icon: "",
                                                  color: "blue",
                                                  loading: _vm.loading,
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "34" } },
                                              [_vm._v("mdi-progress-clock")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_vm._v(" "), _c("span", [_vm._v("Pendiente")])]
                          ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "90vw" },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _vm.dialogDetail
            ? _c("UserPrecenseList", {
                attrs: { profile: _vm.profile },
                on: {
                  closeDialog: function ($event) {
                    return _vm.closeDialog()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }