var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)", "min-height": "100%" },
    },
    [
      _c(
        "v-tabs",
        {
          attrs: { "background-color": "transparent" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab", [_vm._v("Chats")]),
          _vm._v(" "),
          _c("v-tab", [_vm._v("Enviados")]),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.finishedLoading
        ? _c(
            "v-row",
            { staticClass: "title" },
            [
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
                1
              ),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "auto" } }, [
                _vm._v("\n            Cargando contactos...\n        "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              (_vm.continueMailing && this.currentMailContact > 0) ||
              (_vm.continueMailingSearch &&
                this.currentMailContactSearch > 0) ||
              (_vm.continueMessaging && this.currentMessageContact > 0)
                ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
                : _vm._e(),
              _vm._v(" "),
              _vm.continueMessaging
                ? _c("div", [
                    _vm._v(
                      " Enviando Mensajes: " +
                        _vm._s(this.currentMessageContact) +
                        " de " +
                        _vm._s(this.countMessageContact)
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("v-spacer"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.finishedLoading
        ? _c(
            "v-tabs-items",
            {
              staticClass: "transparent",
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { dark: "", color: "rgba(0,0,0,0)" } },
                    [
                      _c(
                        "v-subheader",
                        [
                          _c(
                            "v-row",
                            { staticClass: "title" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "error" },
                                      on: { click: _vm.stopMessaging },
                                    },
                                    [_vm._v("Stop Chat")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        {
                          staticClass: "pt-0 accounts-container custom-scroll",
                        },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-radio-group",
                                {
                                  attrs: { row: "" },
                                  model: {
                                    value: _vm.chatType,
                                    callback: function ($$v) {
                                      _vm.chatType = $$v
                                    },
                                    expression: "chatType",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.getTextboxLabel(1),
                                      value: 1,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.getTextboxLabel(2),
                                      value: 2,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-textarea", {
                            attrs: {
                              label: _vm.getTextboxLabel(_vm.chatType),
                              placeholder:
                                "Ingrese los IDs separados por comas de los clientes",
                              outlined: "",
                              rows: "3",
                            },
                            model: {
                              value: _vm.chatBlacklist,
                              callback: function ($$v) {
                                _vm.chatBlacklist = $$v
                              },
                              expression: "chatBlacklist",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "d-flex" },
                        [
                          _c("v-file-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showInput,
                                expression: "showInput",
                              },
                            ],
                            model: {
                              value: _vm.messageAttatchment,
                              callback: function ($$v) {
                                _vm.messageAttatchment = $$v
                              },
                              expression: "messageAttatchment",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showInput,
                                expression: "!showInput",
                              },
                            ],
                            attrs: {
                              label: "Chats Masivos",
                              outlined: "",
                              placeholder:
                                "Ingrese el mensaje que desea enviarle a todos los contactos",
                            },
                            model: {
                              value: _vm.newMessage,
                              callback: function ($$v) {
                                _vm.newMessage = $$v
                              },
                              expression: "newMessage",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: { click: _vm.toggleInput },
                            },
                            [_c("v-icon", [_vm._v("mdi-paperclip")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.loadingChats,
                              },
                              on: { click: _vm.startMessaging },
                            },
                            [_vm._v("Send")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { dark: "", color: "rgba(0,0,0,0)" } },
                    [
                      _c(
                        "v-card-text",
                        {
                          staticClass: "pt-0 accounts-container custom-scroll",
                        },
                        [
                          _c(
                            "v-list",
                            {
                              attrs: {
                                subheader: "",
                                "two-line": "",
                                dense: "",
                                color: "rgba(0,0,0,0.4)",
                              },
                            },
                            _vm._l(_vm.sentMessages, function (contact, index) {
                              return _c(
                                "v-container",
                                { key: index },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      class: _vm.getChatBubbleColor(contact[1]),
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "text-wrap" },
                                            [_vm._v(_vm._s(contact[0]))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }